import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Once upon a time in the kingdom of Sen lived a princess named Lorelei. She was beautiful like the moonlight who shines on water at nighttime, like the sun turning honey into gold or the delicate perfume of rain. Long white hair, just like starlight. Her skin as fragile as the oldest chinese porcelain and lips soft like cotton candy clouds. Along the years, she was complimented again and again on her ethereal beauty, although these compliments never seemed to move her. Lorelei’s sight was stolen by the gods from birth, she was cursed to never admire her own beauty. The princess never pursued expensive dresses, shiny jewelry, fancy parties or even love. The only thing she desired more than anything was to hear music, music that would bring tears to her eyes, music that would make her float in the sky.`}</p>
    <p>{`One day, the King announced that anyone who would be able to kill the Witch haunting the lands of the kingdom would have his daughter’s hand. After this declaration, soldiers, knights, princes, and dukes tried killing the Witch. It was in vain. The Witch was so hideous, even the bravest man, the strongest knight wouldn’t be able to approach her. Days went by, weeks and months and Lorelei was still waiting, listening to the sound of footsteps for the arrival of her future husband. No one ever passed the castle gates.`}</p>
    <p>{`One night, the princess had had enough! She took her father’s sword and snuck out of the castle. She was determined to kill the Witch herself. She couldn’t see her face, “What could be the worst scenario?” asked Lorelei to herself. Once she arrived at the Witch’s cave, she heard something, the most divine voice she had ever heard. It was just like the moonlight who shines on water at nighttime, like the sun turning honey into gold or the delicate perfume of the rain. She felt tears built up in her eyes, her hands were trembling and her heart was racing. Lorelei took a deep breath and stepped into the cave leaving her father’s sword behind. When she finally felt the Witch’s presence, the princess asked, “Were you the one singing?”. The Witch, surprised that the princess would come visit her, gasped. What surprised her even more was the ethereal beauty Loreliei owned. The Witch slowly answered “Yes, it was me,”. Lorelei eagerly asked “What’s your name?”. “Nuru” responded to the Witch, feeling a little bit more at ease seeing the white princess’s orbs.`}</p>
    <p>{`The both of them didn’t need much time before taking each other’s hands and running away together. The next morning, the kingdom had no more princess nor witch. They had ran away somewhere where they could admire each other’s beauty forever.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      